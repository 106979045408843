<template>
  <div style="padding: 30px;">
    <v-data-table
      :items="leadsModeables"
      :headers="headers"
      no-data-text="Sin leads para mostrar"
      loading-text="Buscando leads..."
      :loading="loading"
      :search="search"
    >
      <template v-slot:top>
        <v-row style="padding: 15px;">
          <v-col cols="3" xl="2">
            <v-checkbox
              v-model="checkAll"
              color="primary"
              label="Seleccionar todos"
              hide-details
            >
            </v-checkbox>
          </v-col>
          <v-col cols="9" xl="8">
            <v-text-field
              v-model="search"
              prepend-icon="mdi-magnify"
              label="Buscar   "
              single-line
              hide-details
              variant="underlined"
            >
            </v-text-field>
          </v-col>
          <v-col cols="12" xl="2">
            <v-btn
              color="success"
              @click="dialog = true"
              block
              :disabled="hasChats"
            >
              <v-icon small class="mr-2"
                >mdi mdi-format-list-bulleted-square</v-icon
              >
              <span>Enviar chats masivos</span>
            </v-btn>
          </v-col>
        </v-row>
      </template>
      <template v-slot:item.chat="{ item }">
        <v-btn icon small>
          <div>
            <v-checkbox
              v-model="item.chat"
              :disabled="hastHundredChats && !item.chat"
            ></v-checkbox>
          </div>
        </v-btn>
      </template>
      <template v-slot:item.operador_id="{ item }">
        {{ getOperadorName(item.operador_id) }}
      </template>
    </v-data-table>
    <CommonActionModal
      :dialog="dialog"
      :title="titleModal"
      :subtitle="subtitleModal"
      :bottonText="bottonText"
      @userChoice="formatData"
    />
  </div>
</template>

<script>
//import { mainAxios } from "@/mainAxios.js";
import CommonActionModal from "@/components/commonComponents/CommonActionModal.vue";

export default {
  props: {
    loading: {
      type: Boolean,
      default: false,
    },
    chatsLeads: {
      type: Array,
      default: () => [],
    },
    operadoresList: {
      type: Array,
      default: () => [],
    },
    campaniaList: {
      type: Array,
      default: () => [],
    },
    currentFilters: {
      type: Object,
      default: () => {},
    },
  },
  components: {
    CommonActionModal,
  },
  data() {
    return {
      search: null,
      checkAll: false,
      current_leds: [],
      headers: [
        {
          text: "Seleccionar",
          align: "center",
          value: "chat",
        },
        {
          text: "Fecha lead",
          value: "fecha_lead",
        },
        {
          text: "Campaña",
          value: "medio",
        },
        {
          text: "Nombre",
          value: "nombre",
        },
        {
          text: "Teléfono",
          value: "telefono",
        },
        {
          text: "Estatus Llamada",
          value: "status_llamada",
        },
        {
          text: "Ultima disposición de llamada",
          value: "disposicion",
        },
        {
          text: "Ultimo operador",
          value: "operador_id",
        },
        {
          text: "Intentos de llamadas",
          value: "intentos",
        },
        {
          text: "Estatus chat",
          value: "statusChat",
        },
        {
          text: "Ultima dispoción de chat",
          value: "ultimaDisposicionChat",
        },
        {
          text: "Intentos chat",
          value: "intentosChat",
        },
      ],
      currentTotalInTable: [],
      moldeableLeads: [],
      leadsModeables: [],
      dialog: false,
      titleModal: "Aplicacion de campaña de chats",
      subtitleModal:
        "¿Estas seguro de enviar la campaña de chats a estos leads ?",
      bottonText: "Aplicar chats",
    };
  },
  computed: {
    hasChats() {
      var num = 0;
      this.leadsModeables.map((e) => {
        if (e.chat) num++;
      });
      return num <= 0;
    },
    hastHundredChats() {
      var result = 0;
      for (var i = 0; i < this.leadsModeables.length; i++) {
        if (this.leadsModeables[i].chat) result++;
      }
      return result >= 100;
    },
  },
  watch: {
    checkAll(val) {
      if (val) {
        var max =
          this.leadsModeables.length <= 100 ? this.leadsModeables.length : 100;
        if (max == 100) this.infoModal();
      } else max = this.leadsModeables.length;
      for (var i = 0; i < max; i++) {
        this.leadsModeables[i].chat = val;
      }
    },
    chatsLeads() {
      this.leadsModeables = this.chatsLeads;
    },
  },
  methods: {
    getOperadorName(id) {
      return this.operadoresList.find((e) => e.id == id)
        ? this.operadoresList.find((e) => e.id == id).nombreCompleto
        : "Sin operador asignado";
    },
    formatData(val) {
      if (val) {
        var formToChat = [];
        this.leadsModeables.map((e) => {
          if (e.chat) formToChat.push(this.formatLeads(e));
        });
        this.$emit("goToChats", {
          chat_service: this.getServicioChat(),
          template_chat_id: this.getTemplateId(),
          record_type: "leads",
          app_id: this.getAppId(),
          data: formToChat,
        });
      }
      this.dialog = false;
    },
    formatLeads(e) {
      var jsonData = JSON.parse(e.jsonRequest);
      switch (this.getTemplateId()) {
        case "lead_medico":
          return {
            origen_uuid: e.uuid ?? "",
            lead_uuid: e.uuid ?? "",
            phone_number: "+52" + e.telefono,
            name: e.nombre ?? "",
            chat_type: "outbound",
            did: "+52" + this.getDidInbound(),
            tipo_registro: "leads",
          };
        case "lead_mascota":
          return {
            origen_uuid: e.uuid ?? "",
            lead_uuid: e.uuid ?? "",
            phone_number: "+52" + e.telefono,
            name: e.nombre ?? "",
            chat_type: "outbound",
            did: "+52" + this.getDidInbound(),
            tipo_registro: "leads",
            pet_name: jsonData.nombre_mascota ?? "Macota",
          };
        case "lead_coche":
          return {
            origen_uuid: e.uuid ?? "",
            lead_uuid: e.uuid ?? "",
            phone_number: "+52" + e.telefono,
            name: e.nombre ?? "",
            chat_type: "outbound",
            did: "+52" + this.getDidInbound(),
            tipo_registro: "leads",
            car_brand: jsonData.version ?? "Coche",
          };
        default:
          return "No tiene na";
      }
    },
    getTemplateId() {
      return this.campaniaList.find((e) => e.id == this.currentFilters.campaña)
        .idTemplateChat;
    },
    getDidInbound() {
      return this.campaniaList.find((e) => e.id == this.currentFilters.campaña)
        .didInbound;
    },
    getAppId() {
      return this.campaniaList.find((e) => e.id == this.currentFilters.campaña)
        .appId;
    },
    getServicioChat() {
      return this.campaniaList.find((e) => e.id == this.currentFilters.campaña)
        .servicioChat;
    },
    infoModal() {
      this.$toast.info(
        "El límite actual es de 100 chats a la vez, se seleccionaron los primeros 100 registros mostrados en la tabla.",
        {
          position: "top-right",
          timeout: 5000,
          closeOnClick: true,
          pauseOnFocusLoss: true,
          pauseOnHover: true,
          draggable: true,
          draggablePercent: 0.6,
          showCloseButtonOnHover: false,
          hideProgressBar: true,
          closeButton: "button",
          icon: true,
          rtl: false,
        }
      );
    },
  },
};
</script>

<style scoped>
.theme--light.v-data-table {
  background-color: #f2fafc;
  color: rgba(0, 0, 0, 0.87);
  border-radius: 20px;
}
</style>
