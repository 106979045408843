<template>
  <div>
    <v-expansion-panels elevation="0" style="padding: 30px;" v-model="panel">
      <v-expansion-panel elevation="0">
        <v-expansion-panel-header style="padding: 35px;">
          <h3 style="color:#42352E;">
            Filtros
          </h3>
        </v-expansion-panel-header>
        <v-expansion-panel-content>
          <commond-form
            :request="form"
            :onlyValidations="true"
            :run="run"
            @cancel="run = false"
            @afterValidation="updateSearch"
          >
          <template v-slot:data>
            <v-row dense>
              <v-col cols="12" md="3">
                <v-autocomplete
                  label="Por tipo de servicio"
                  :items="[
                    {
                      name: 'Llamada',
                      id: 'llamada',
                    },
                    {
                      name: 'Chats',
                      id: 'chat',
                    },
                  ]"               
                  item-text="name"
                  item-value="id"
                  v-model="tipo_servicio"
                  outlined
                  required
                  background-color="#FFFFFF"
                  :rules="requiredRule"
                >
                </v-autocomplete>
              </v-col>
              <v-col cols="12" md="3" v-if="tipo_servicio != null">
                <v-autocomplete
                  label="Ramo"
                  :items="ramoList"
                  item-text="etiqueta"
                  item-value="ramo"
                  v-model="form.ramo"
                  outlined
                  required
                  background-color="#FFFFFF"
                  :rules="requiredRule"
                  :clearable="true"
                >
                </v-autocomplete>
              </v-col>
              <v-col cols="12" md="3" v-if="tipo_servicio != null">
                <v-autocomplete 
                  label="Canal"
                  :items="canalList"
                  item-text="etiqueta"
                  item-value="canal"
                  v-model="form.canal"
                  outlined
                  required
                  background-color="#FFFFFF"
                  :rules="requiredRule"
                  :clearable="true"
                >
                </v-autocomplete>
              </v-col>
              <v-col cols="12" md="3" v-if="tipo_servicio != null && form.canal != null && form.ramo != null">
                <v-autocomplete
                  label="Campaña"
                  :items="currentCampanias"
                  v-model="form.campaña"
                  item-text="producto"
                  item-value="id"
                  outlined
                  required
                  background-color="#FFFFFF"
                  :rules="requiredRule"
                  :clearable="true"
                >
                </v-autocomplete>
              </v-col>
              <v-col cols="12" md="3" v-if="tipo_servicio == 'llamada' && form.canal != null && form.ramo != null">
                <v-menu
                  v-model="menuFecha"
                  ref="menu"
                  :close-on-content-click="false"
                  transition="scale-transition"
                  offset-y
                  min-width="auto"
                >
                  <template #activator="{ on, attrs }">
                    <v-text-field
                      :value="textValue"
                      label="Rango de fechas"
                      outlined
                      v-on="on"
                      v-bind="attrs"
                      readonly
                      prepend-inner-icon="mdi-calendar"
                      :light="true"
                      background-color="white"
                      type="text"
                      :rules="requiredRule"
                      :error-messages="errores.fechas"
                    ></v-text-field>
                  </template>
                  <v-card>
                    <v-date-picker
                      v-model="fechas"
                      range
                      locale="es"
                      :selected-items-text="
                        `${fechas.length} Seleccionados`
                      "
                      :max="
                        new Date(
                          Date.now() - new Date().getTimezoneOffset() * 60000
                        )
                          .toISOString()
                          .substring(0, 10)
                      "
                      min="1950-01-01"
                    ></v-date-picker>
                    <v-card-actions>
                      <v-btn small text @click="fechas = []"
                        >Limpiar</v-btn
                      >
                      <v-spacer></v-spacer>
                      <v-btn
                        small
                        text
                        color="primary"
                        @click="menuFecha = false"
                        >Listo</v-btn
                      >
                    </v-card-actions>
                  </v-card>
                </v-menu>
              </v-col>
              <v-col cols="12" md="3" v-if="tipo_servicio != null && form.canal != null && form.ramo != null">
                <v-autocomplete
                  label="Estatus leads"
                  :items="currentStatus"
                  v-model="form.estatus"
                  outlined
                  required
                  background-color="#FFFFFF"
                  :rules="requiredRule" 
                  :clearable="true"
                >
                </v-autocomplete>
              </v-col>
              <v-col cols="12" md="3" v-if="tipo_servicio != null && form.canal != null && form.ramo != null && form.estatus != 'Nuevo'">
                <v-autocomplete
                  label="Por disposición"
                  :items="disposicionesList"
                  v-model="form.disposicion"
                  item-text="disposicion"
                  item-value="disposicion"
                  outlined
                  required
                  background-color="#FFFFFF"
                  :clearable="true"
                >
                </v-autocomplete>
              </v-col>
              <v-col cols="12" md="3" v-if="tipo_servicio != null && form.canal != null && form.ramo != null">
                <v-autocomplete
                  label="Por No. Intentos"
                  :items="currentIntentos"
                  v-model="form.intentos"
                  outlined
                  required
                  background-color="#FFFFFF"
                  :clearable="true"
                >
                </v-autocomplete>
              </v-col>
              <v-col cols="12" md="3" v-if="tipo_servicio != null && form.canal != null && form.ramo != null">
                <v-autocomplete
                  label="Por Operador"
                  :items="operadoresList"
                  v-model="form.operador"
                  item-text="nombreCompleto"
                  item-value="id"
                  outlined
                  required
                  background-color="#FFFFFF"
                  :clearable="true"
                >
                </v-autocomplete>
              </v-col>
            </v-row>
          </template>
        </commond-form>
            <v-row style="align-items: end; text-align: end;">
              <v-col cols="12">
                <v-btn
                  class="common-botton"
                  dark
                  :loading="loading"
                  @click="erroresDate()"
                  style="margin-right: 10px;"
                >
                  Filtrar
                </v-btn>
                <v-btn
                  dark
                  outlined
                  color="#00a7e4"
                  rounded
                  @click="clearForm()"
                >
                  Limpiar
                </v-btn>
              </v-col>
            </v-row>
        </v-expansion-panel-content>
      </v-expansion-panel>
    </v-expansion-panels>
  </div>
</template>

<script>
import moment from "moment";
import CommondForm from "@/components/commonComponents/CommondForm.vue";

export default {
  components: {
    CommondForm
  },
  props: {
    canalList: Array,
    ramoList: Array,
    campaniaList: Array,
    disposicionesList: Array,
    operadoresList: Array,
    loading:Boolean,
    cleanFilters:Boolean
  },
  data() {
    return {
      roles: JSON.parse(localStorage.getItem("roles")),
      form: {
        ramo:null,
        canal:null,
        campaña: null,
        fechaInicio:null,
        fechaFin:null,
      },
      fechas: [],
      tipo_servicio: null,
      run:false,
      menuFecha: false,
      panel: 0,
      localRules: {
        longDigit(number) {
          return (v) =>
            !v ||
            v.length == number ||
            "Debe proporcionar " + number + " digitos";
        },
      },
      requiredRule: [(v) => !!v || "El campo es requerido"],
      errores:{
        fechas:[]
      }
    };
  },
  computed: {
    textValue() {
      return `Fechas de  ${this.formatDate(this.fechas[0]) ||
        "--/--/--"}  a  ${this.formatDate(this.fechas[1]) || "--/--/--"}`;
    },
    currentCampanias() {
      if (this.form.canal != null && this.form.ramo != null)
        return this.campaniaList.filter(
          (e) => e.canal == this.form.canal && e.ramo == this.form.ramo
        );
      else return [];
    },
    currentStatus(){
        if(this.tipo_servicio!= null){
            switch(this.tipo_servicio){
                case 'llamada':
                    return ['Nuevo', 'Seguimiento', 'Finalizado', 'Cancelado']
                case 'chat':
                    return ['Nuevo', 'Seguimiento', 'Finalizado', 'Cancelado']
                default: return []
            }
        }else
            return []
    },
    currentIntentos(){
        var result = []
        if(this.tipo_servicio != null && this.form.campaña != null){
            var currentCampaña = this.currentCampanias.find((e) => e.id == this.form.campaña);
            var numIntentos = this.tipo_servicio == 'llamada' ? currentCampaña.intentos : currentCampaña.intentosChat
            var sum = 0
            while(sum <= numIntentos){
                result.push(sum)
                sum++
            }
            return result
        }else
            return []
    },
  },
  watch:{
    tipo_servicio(){
      this.form = {
        ramo:null,
        canal:null,
        campaña: null,
        fechaInicio:null,
        fechaFin:null,
      }
      this.fechas = []
    },
    fechas(){
      if(this.fechas.length < 2)
        this.errores.fechas = ['El campo fechas es obligarorio y debe de ser un rango']
      else
        this.errores.fechas = []
    },
    cleanFilters(val){
      if(val)
        this.clearForm()
    }
  },
  methods: {
    formatDate(enDate) {
      if (!enDate) return null;
      const dateArray = enDate.split("-");
      return dateArray.reverse().join("/");
    },
    updateSearch(){
      if (this.fechas.length > 0) {
        if (this.fechas[1]) {
          this.form.fechaInicio = moment(this.fechas[0])
            .tz("America/Mexico_City", true)
            .tz("UTC")
            .format("YYYY-MM-DD HH:mm:ss");
          this.form.fechaFin = moment(this.fechas[1])
            .tz("America/Mexico_City", true)
            .tz("UTC")
            .add(24, "hours")
            .subtract(1, "seconds")
            .format("YYYY-MM-DD HH:mm:ss");
        } else {
          this.form.fechaInicio = moment(this.fechas[0])
            .tz("America/Mexico_City", true)
            .tz("UTC")
            .format("YYYY-MM-DD HH:mm:ss");
          this.form.fechaFin = moment(this.fechas[0])
            .tz("America/Mexico_City", true)
            .tz("UTC")
            .add(24, "hours")
            .subtract(1, "seconds")
            .format("YYYY-MM-DD HH:mm:ss");
        }
      }
      var keys = Object.keys(this.form)
      keys.map(e => {
        if(this.form[e] == null)
          delete this.form[e]
      })
        this.$emit('updateSearch',{
            tipo_servicio:this.tipo_servicio,
            form:this.form
        })        
    },
    clearForm(){
      this.form = {
        ramo:null,
        canal:null,
        campaña: null,
        fechaInicio:null,
        fechaFin:null,
      }
      this.fechas = []
      this.tipo_servicio = null
      this.$emit('cleanLeads')
    },
    erroresDate(){
      this.run = true
      if(this.fechas.length < 2)
        this.errores.fechas = ['El campo fechas es obligarorio y debe de ser un rango']
      else
        this.errores.fechas = []
    }
  },
};
</script>

<style scoped>
.v-expansion-panel {
  border-radius: 20px;
}
.v-expansion-panels {
  z-index: auto !important;
}
.v-expansion-panels:not(.v-expansion-panels--accordion):not(.v-expansion-panels--tile)
  > .v-expansion-panel--active {
  border-radius: 20px !important;
  background-color: #f7f8fa;
}
.v-expansion-panel-header {
  background-color: #f7f8fa;
  border-radius: 20px !important;
}
.v-input__slot {
  height: 57px !important;
}
</style>
